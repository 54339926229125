<template>
  <SmallContainer>
    <div v-html="htmlText" class="static-page"></div>
  </SmallContainer>
</template>

<script>
import showdown from "showdown";
import SmallContainer from "../components/base/SmallContainer";
import loadingMixin from "../mixins/loadingMixin";
import localizeMixin from "../mixins/localizeMixin";

export default {
  name: "MarkdownPage",
  components: { SmallContainer },
  data: () => ({
    mdToHtml: null,
    mdText: null
  }),
  mixins: [loadingMixin, localizeMixin],
  computed: {
    htmlText() {
      if (this.mdToHtml) {
        return this.mdToHtml.makeHtml(this.mdText);
      }
      return null;
    }
  },
  mounted() {
    try {
      this.mdText = require(`@/assets/markdown/${this.language}/${this.$route.meta.markdown}.md`).default;
    } catch {
      try {
        this.mdText = require(`@/assets/markdown/${this.otherLang}/${this.$route.meta.markdown}.md`).default;
      } catch {
        this.notFound = true;
        return;
      }
    }
    if (!this.mdText) {
      this.notFound = true;
      return;
    }
    // this.mdText.
    this.mdToHtml = new showdown.Converter();
  }
};
</script>

<style lang="scss">
.static-page {
  img {
    width: 100%;
  }
}
</style>
